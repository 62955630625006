import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { Observable, of } from 'rxjs';
import { delay } from "rxjs/operators";
import { EstabelecimentoConversaService } from '../../../../app/admin/_services/estabelecimentoConversa';
export class DemoAdapter extends ChatAdapter implements IChatGroupAdapter {
  userId: any;
  constructor(
    private _estabelecimentoConversaService: EstabelecimentoConversaService) { super() }

  //

  public static mockedParticipants: IChatParticipant[] = [
    
  ];


  listFriends(): Observable<ParticipantResponse[]> {
    return of(DemoAdapter.mockedParticipants.map(user => {
      let participantResponse = new ParticipantResponse();

      participantResponse.participant = user;
      participantResponse.metadata = {
        totalUnreadMessages: Math.floor(Math.random() * 10)
      }
      return participantResponse;
    }));
  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {

    let mockedHistory: Array<Message>;
    var conversa = [];

    this._estabelecimentoConversaService.selectConversa({ "fkEstabelecimento": destinataryId }).then(data => {


      if (data.payload.length > 0) {
        for (var i = 0; i < data.payload.length; i++) {
          
          var aux =
          {
            fromId: data.payload[i].fkEstabelecimento,
            toId: data.payload[i].fkEstabelecimento == data.payload[i].fkEstabelecimento_1 ? data.payload[i].fkEstabelecimento_1 : data.payload[i].fkEstabelecimento_2,
            message: data.payload[i].mensagem,
            dateSent: new Date(data.payload.dataCriacao)
          }
          conversa.push(aux);
        }
      }

    });

    mockedHistory = conversa;


    return of(mockedHistory).pipe(delay(2000));

  }

  sendMessage(message: Message): void {
    setTimeout(() => {
      let replyMessage = new Message();

      replyMessage.message = message.message;
      replyMessage.dateSent = new Date();

      
      
        replyMessage.fromId = message.toId;
        replyMessage.toId = message.fromId;

        this._estabelecimentoConversaService.insertMensagem(replyMessage).then(data => {});

        let user = DemoAdapter.mockedParticipants.find(x => x.id == replyMessage.toId);
        this.onMessageReceived(user, replyMessage);
      
    }, 1000);
  }

  groupCreated(group: Group): void {
    DemoAdapter.mockedParticipants.push(group);

    DemoAdapter.mockedParticipants = DemoAdapter.mockedParticipants.sort((first, second) =>
      second.displayName > first.displayName ? -1 : 1
    );

    // Trigger update of friends list
    this.listFriends().subscribe(response => {
      this.onFriendsListChanged(response);
    });
  }
}
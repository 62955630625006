import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AuthService } from '../../../admin/_services/auth.service';
import { Helpers } from '../../../helpers';
import { Router } from '@angular/router';
import { environment } from "../../../../environments/environment.prod";
declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {
    public URL = `${environment.domain}`;
    infoEstabelecimento: any = [];
    imagem: any;

    constructor(private _authService: AuthService, private _router: Router) {

    }
    ngOnInit() {
        this.getInfoEstabelecimento();
    }
    getInfoEstabelecimento(){
        let data = localStorage.getItem('pipoohUser'); 
        this._authService.getTokenEstabelecimento({"token":data}).then(data =>
            {
                this.infoEstabelecimento = data;
                this.infoEstabelecimento = this.infoEstabelecimento.payload[0];
                if(this.infoEstabelecimento == false || this.infoEstabelecimento == null) { this._authService.logout();  this._router.navigate([`/logout`]); }
                this.imagem = "https://img.pipooh.com.br/" + this.infoEstabelecimento.foto;
            }).catch(e => { console.log(e); });
    }
    ngAfterViewInit() {

        mLayout.initAside();

    }

}
import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { UserService } from './_services/user.service';
import { AlertComponent } from './_directives/alert.component';
import { LoginCustom } from './_helpers/login-custom';
import { Helpers } from '../helpers';
import * as moment from 'moment';
import { HelperService } from '../admin/_services/helper.service';
import { AuthService } from '../admin/_services/auth.service';
import { EstabelecimentoService } from '../admin/_services/estabelecimento.service';
import { HttpClient } from '@angular/common/http';
import { CupomPedidoService } from '../admin/_services/cupomPedido.service';
import { environment } from '../../environments/environment.prod';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-1.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AuthComponent implements OnInit {
    public URL = `${environment.domain}`;
    model: any = {};
    loading = false;
    returnUrl: string;
    regioes: any = [];
    lat: string;
    long: string;
    coords: any;
    user: any;
    modalidades: any = ["Ecommerce", "Físico", "Franquia", "Físico e Ecommerce", "Servico-Gift", "Venda Direta", "Eventos", "Fornecedor"]
    categorias: any = [];
    imagem: any;
    cadastrar: any = false;
    @ViewChild('alertSignin', { read: ViewContainerRef, static: true }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup', { read: ViewContainerRef, static: true }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass', { read: ViewContainerRef, static: true }) alertForgotPass: ViewContainerRef;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _alertService: AlertService,
        private toastr: ToastrService,
        private _helperService: HelperService,
        private cfr: ComponentFactoryResolver,
        private authService: AuthService,
        private http: HttpClient,
        private estabelecimentoService: EstabelecimentoService,
        private _cupomPedidoService: CupomPedidoService) {
    }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        this._router.navigate([this.returnUrl]);
        this.model.modalidade = "Ecommerce";
        this.buscarCategorias();
        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
        if (sessionStorage.getItem('cadastro') != undefined) {
            document.getElementById('m_login_signup').click();
            LoginCustom.displaySignUpForm()
        }
    }

    signin() {
        this.loading = true;
        var obj = { "email": this.model.email, "senha": this.model.senha };
        this.authService.login(obj).then(data => {
            if (data.success) {
                console.log("data: ", data);
                this.user = data;
                if (this.user.payload) {
                } if (this.user.payload.length > 0) {
                    //Funcionou
                    localStorage.setItem('pipoohUser', this.user.payload[0].token);
                    this._router.navigate([this.returnUrl]);
                } else {
                    this._alertService.error(
                        'E-mail ou senha inválida.',
                        true);
                }

                this.showAlert('alertSignin');
                this.loading = false;
            } else {
                this._alertService.error(
                    'E-mail ou senha inválida.',
                    true);
                this.loading = false;
            }

        })
    }
    forgotPass() {
        this.loading = true;
        var aux: any = [];
        var obj = { "email": this.model.email };
        this.authService.forgotPassword(obj).toPromise().then(data => {
            this.user = data;
            this.showAlert('alertSignin');
            if (this.user.payload) {
                if (this.user.payload.length > 1) {
                    localStorage.setItem('pipoohUser', this.user.payload[0].token);
                } else {
                    this.toastr.error('E-mail informado não está cadastrado.', 'Falha na recuperação');
                }

            }
            this.loading = false;
            this.model = {};
        })


        // this._authService.forgotPassword(this.model.email).then(data => {
        //     console.log(data);
        //     aux = data;
        //     this.showAlert('alertSignin');
        //     if (data == false) {
        //         this.toastr.error('E-mail informado não está cadastrado.', 'Falha na recuperação');
        //     }
        //     else {
        //         this._alertService.success(
        //             'Legal! A instrução de recuperação de senha foi enviada para seu e-mail.',
        //             true);
        //         //this._router.navigate([this.returnUrl]);
        //         LoginCustom.displaySignInForm();
        //     }
        //     this.loading = false;
        //     this.model = {};
        // })
    }
    logout() {
        localStorage.removeItem('pipoohUser');
    }
    signUp() {
        this.loading = true;
        if (this.model.senha != this.model.csenha) {
            this.toastr.error("Senhas não coincidem");
            this.loading = false;
            return;
        }
        if (this.model.codigo != "" && this.model.codigo != null) {
            this._cupomPedidoService.buscarCupomNome({ "nome": this.model.codigo }).then(data => {
                var aux = data;
                if (aux.payload == false) {
                    this.loading = false;
                    return;
                } else {
                    if (aux.payload.length > 0) {
                        var cupom = aux.payload[0];
                        if (cupom.ativo == 0 || cupom.quantidade >= cupom.quantidadeLimite) {
                            this.toastr.error("Cupom inválido");
                            this.loading = false;
                            return;
                        }
                    }
                }
            }).catch(e => {
                this.toastr.error("Cupom inválido");
                this.loading = false;
                return false;
            });

        }
        this.estabelecimentoService.preCadastro(this.model).then(data => {
            var pedido = data.payload;
            for (var i = 0; i < data.payload.length; i++) {
                pedido = data.payload[i].token
            }
            this.goToUrl(pedido)
            this.loading = false;
        })
        this.loading = false;
    }

    consultaCEP() {
        this.consultaCEPAPI(this.model.cep).toPromise().then(data => {
            if (data) {
                var address: any = data;
                if (address.cep) {
                    // console.log(data);
                    this.model.endereco = address.logradouro;
                    this.model.bairro = address.bairro;
                    this.model.cidade = address.localidade;
                    this.model.uf = address.uf;
                }
                else {
                    this.model.endereco = "";
                    this.model.bairro = "";
                    this.model.cidade = "";
                    this.model.uf = "";
                }
            }
        });

    }

    consultaCEPAPI(cep: string) {
        // Nova variável "cep" somente com dígitos.
        cep = cep.replace(/\D/g, '');

        // Verifica se campo cep possui valor informado.
        if (cep !== '') {
            // Expressão regular para validar o CEP.
            const validacep = /^[0-9]{8}$/;

            // Valida o formato do CEP.
            if (validacep.test(cep)) {
                return this.http.get(`//viacep.com.br/ws/${cep}/json`);
            }
        }
    }

    loginClick() {
        LoginCustom.displaySignInForm();
    }
    goToUrl(token) {
        var url = "https://checkout.pipooh.com.br/?id=" + token;
        window.location.href = url;
    }
    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
    buscarCategorias() {
        this.estabelecimentoService.buscarCategorias({}).then(data => {
            this.categorias = data.payload
            this.model.categoria = this.categorias[0].nome;
        })
    }
}
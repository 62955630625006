import { Injectable } from "@angular/core";
import { HttpClient   } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { AuthService } from '../../admin/_services/auth.service';
import { Router } from "@angular/router";
import { AlertService } from ".";

@Injectable()
export class AuthenticationService {

    user: any = [];
    constructor(private _router: Router,private authService: AuthService, private _alertService: AlertService,private http: HttpClient  ) {
    }

    async login(email: string, password: string) {
        var obj = { "email": email, "pass": password };
        await this.authService.login(obj).then(data => {
            console.log("data: ", data);
            this.user = data;
        }).catch(function (e){
            console.log(e);
            return false;
        });
        if(this.user.payload){
            if(this.user.payload.length == 0){
                return false;
            }
            return await localStorage.setItem('pipoohUser', this.user.token);
        }
        else{
            return false;
        }           
             
    }
    async codigoCheck(codigo: string)
    {
        return await this.authService.codigoCheck({"codigo":codigo}).toPromise().then(data => 
            {
                var aux : any = data;
                console.log("cCheck: ",aux);
                return aux;
            });
    }
    async signUp(name: string, sexo: string, email: string, password: string, passwordConfirm: string,  celular: string,  ultimaMenstruacao: string,  ref: string,  codigo: string, franquiaID: string) {
        if (password != passwordConfirm) {console.log("xablau"); return false;}

        var obj = { "name": name, "email": email, "password": password, "sexo": sexo , "celular": celular , "ultimaMenstruacao": ultimaMenstruacao, "ref": ref, "codigo": codigo, "fkFranquia": franquiaID };
        await this.authService.signUp(obj).toPromise().then(data => {
            //console.log(data);
            this.user = data;
        }).catch(function (e){
            console.log(e);
            return false;
        });
        if(this.user.payload){
            if(this.user.payload.length == 0){
                return false;
            }
            console.log(this.user.payload);
            if(String(this.user.payload).substring(0,15) == "SQLSTATE[23000]"){ return "E-mail cadastrado";}
            else{
                localStorage.setItem('pipoohNewHome', '1');
                localStorage.setItem('pipoohNewResgate', '1'); 
                localStorage.setItem('pipoohNewConvite', '1');
                localStorage.setItem('pipoohNewRifa', '1');
                localStorage.setItem('pipoohNewProfile', '1');
                localStorage.setItem('pipoohNewProfileCha', '1');
                localStorage.setItem('pipoohNewListProfile', '1');
                return await localStorage.setItem('pipoohUser', this.user.token); 
            }
        }
        else{
            return false;
        }           
    }

    async forgotPassword(email: string) {
        var obj = { "email": email };
        await this.authService.forgotPassword(obj).toPromise().then(data => {
            this.user = data;
        }).catch(function (e){
            console.log(e);
            return false;
        });
        if(this.user.payload){
            if(this.user.payload.length == 0){
                return false;
            }
            return await localStorage.setItem('pipoohUser', this.user.token);
        }
        else{
            return false;
        }           
             
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('pipoohUser');
    }
}
import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';
import { environment } from '../../../../environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { EstabelecimentoConversaService } from '../../../../app/admin/_services/estabelecimentoConversa';
import { ChatAdapter, User } from 'ng-chat';
import { DemoAdapter } from './demo-adapter';
import { IChatController, ChatParticipantType, ChatParticipantStatus } from 'ng-chat';
declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    @ViewChild('ngChatInstance', {static: true})
    protected ngChatInstance: IChatController;
    user: User = {
        participantType: ChatParticipantType.User,
        id: 10,
        displayName: "Lukas",
        avatar: "https://66.media.tumblr.com/avatar_9dd9bb497b75_128.pnj",
        status: ChatParticipantStatus.Online,
      }
    name = 'Angular';
    userId = 999;
    public adapter: ChatAdapter = new DemoAdapter(this._estabelecimentoConversaService);
    //
    public URL = `${environment.domain}`;
    infoEstabelecimento: any = [];
    imagem: any;
    conversas: any = [];
    msg: any = 0;
    //Paginação 
    page = 1;
    pageSize = 3;
    
    constructor(private _authService: AuthService, 
        private _router: Router,
        private _estabelecimentoConversaService: EstabelecimentoConversaService) {

    }
    ngOnInit() {
        this.getInfoEstabelecimento();
    }
    openUserChat() {
        this.ngChatInstance.triggerOpenChatWindow(this.user);
      }
    getInfoEstabelecimento(){
        let data = localStorage.getItem('pipoohUser'); 
        this._authService.getTokenEstabelecimento({"token":data}).then(data =>
            {
                this.infoEstabelecimento = data;
                this.infoEstabelecimento = this.infoEstabelecimento.payload[0];
                if(this.infoEstabelecimento == false || this.infoEstabelecimento == null) { this._authService.logout();  this._router.navigate([`/logout`]); }
                this.userId = this.infoEstabelecimento.id;
                this.imagem = "https://img.pipooh.com.br/" + this.infoEstabelecimento.foto;
                this.buscarConversas();
            }).catch(e => { console.log(e); });
    }
    ngAfterViewInit() {

        mLayout.initHeader();

    }

    buscarConversas(){
        this._estabelecimentoConversaService.selectConversas({}).then(data => {
            this.conversas = data.payload;
            this.msg = 0;
            this.conversas.forEach(conversa => {
                this.msg += conversa.mensagens;
            });
        })
    }

    abrirConversa(estabelecimento) {
        this.user =  {
            participantType: ChatParticipantType.User,
            id: estabelecimento.fkEstabelecimento,
            displayName: estabelecimento.nomeFantasia,
            avatar: "https://img.pipooh.com.br/"+ estabelecimento.foto,
            status: ChatParticipantStatus.Online
          }
          this.openUserChat();
          this.buscarConversas();
    }

}